import { modifyBoardPackage } from '@/services/BoardMeetings/BoardPackage/Put'

import { filterMultiLevelList } from '@/utilities/nestedSort/filterMultiLevelList'

export async function save(paramBoardPackage, paramMultiLevelList) {
  console.debug('save...')

  let boardPackage = {}

  if (paramBoardPackage !== undefined) {
    boardPackage = paramBoardPackage
  } else if (this.boardPackage !== undefined) {
    boardPackage = this.boardPackage
  }

  const { phoneMeetingID, physicalMeetingID, virtualMeetingID } = boardPackage

  if (phoneMeetingID === null) {
    delete boardPackage.phoneMeeting
  }
  if (physicalMeetingID === null) {
    delete boardPackage.physicalMeeting
  }
  if (virtualMeetingID === null) {
    delete boardPackage.virtualMeeting
  }

  let multiLevelList = {}

  if (paramMultiLevelList !== undefined) {
    multiLevelList = paramMultiLevelList
  } else if (this.multiLevelList !== undefined) {
    multiLevelList = this.multiLevelList
  }

  const result = filterMultiLevelList({
    params: ['items', 'duration', 'title', 'startTime'],
    multiLevelList: multiLevelList
  })

  const json = {
    ...boardPackage,
    agendaJson: JSON.stringify(result)
  }

  await modifyBoardPackage({
    json,
    successMessage: 'The board meeting agenda was saved successfully.'
  })
}
