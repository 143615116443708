/*
  import { calcValidParents } from '@/utilities/nestedSort/calcValidParents'
  
  calcValidParents ({
    id: null,
    multiLevelList,
    maxDepth: 3
  })
*/

/*
  for determining the parent items that
  an item (id) can be attached to
*/

export function calcValidParents({ id, multiLevelList, maxDepth = 10 }) {
  const result = []

  function findParents({ branch, depth }) {
    for (let a = 0; a < branch.length; a++) {
      const item = branch[a]
      const {
        id: itemId,
        items,
        //
        title = null,
        duration = null
      } = item

      if (id === itemId) {
        continue
      }

      result.push({
        id: itemId,
        title,
        duration
      })

      if (Array.isArray(items) && items.length >= 1) {
        if (depth === maxDepth - 1) {
          continue
        }

        findParents({
          branch: items,
          depth: depth + 1
        })
      }
    }
  }

  findParents({
    branch: multiLevelList,
    depth: 1
  })

  return result
}
