import { buildMultiLevelList } from '@/utilities/nestedSort/multiLevelList/build'

/*
  this.buildMultiLevel ()
*/

/*
  to be called after:
    refresh
    sort
    add
    remove
*/

export function buildMultiLevel() {
  const startTime = this.boardPackage.meetingDate
  const oneLevelList = this.oneLevelList

  this.multiLevelList = buildMultiLevelList({
    oneLevelList,
    //
    // '3191-12-20T21:10:00'
    startTime
  })
}
