export function eraseEl({ component, duration, id, title }) {
  return {
    kind: 'button',
    className: 'button is-info is-light is-small is-outline is-rounded',
    textContent: 'Delete',
    value: duration,
    styles: {
      position: 'relative',
      marginLeft: '4px'
    },
    on: {
      click: function(e) {
        e.preventDefault()
        e.stopPropagation()

        component.erase({ id, title })
      }
    }
  }
}
