export function addEl({ component, duration, id }) {
  return {
    kind: 'button',
    className: 'button is-info is-light is-small is-outline is-rounded',
    title: 'add',
    textContent: 'Add',
    attributes: {
      adder: ''
    },
    value: duration,
    styles: {
      display: 'inline-block',
      marginLeft: '4px'
    },
    on: {
      click: function(event) {
        event.preventDefault()
        event.stopPropagation()

        component.openAddModal({
          id,
          event
        })
      }
    },
    children: [
      {
        kind: 'div',
        className: '',
        styles: {
          height: '0px',
          width: '0px'
        }
      }
    ]
  }
}
