/*
  import { moveDown } from '@/utilities/nestedSort/multiLevelList/findNext'
  const theItem = moveDown ({ 
    multiLevelList,
    id
  });
*/
import { findId } from '@/utilities/nestedSort/multiLevelList/findId'

function addToParent({ parentItem, item }) {
  const { parentBranch, index } = parentItem

  parentBranch.splice(index + 1, 0, item)
}

export function moveDown({ multiLevelList, id, maxDepth = 100 }) {
  const item = findId({
    branch: multiLevelList,
    id
  })

  const parentBranch = item.parentBranch
  const parentId = item.parentId
  const index = item.index

  const isLast = item.index + 1 === parentBranch.length

  if (!isLast) {
    //
    //  The item is not the last in the parent's item
    //  array, therefore it can be moved one down in the array.
    //
    const nextId = parentBranch[index + 1].id
    const nextItem = findId({
      branch: multiLevelList,
      id: nextId
    })

    const combinedDepth = nextItem.depth + item.levels - 1

    if (combinedDepth < maxDepth) {
      const _item = parentBranch.splice(index, 1)[0]
      nextItem.item.items.unshift(_item)

      return {
        moved: true,
        idChange: true
      }
    }

    // max depth found
    const _item = parentBranch.splice(index, 1)[0]
    parentBranch.splice(index + 1, 0, _item)

    return {
      moved: true,
      idChange: true
    }
  }

  if (isLast && typeof parentId !== 'number') {
    //
    //  The item is the last item of the level 0 array
    //  therefore, it can't be moved down any more.
    //
    return {
      moved: false,
      idChange: false
    }
  }

  //
  //  the item is the last item,
  //  however it is a descendent of
  //  another item
  //

  const parentItem = findId({
    branch: multiLevelList,
    id: parentId
  })

  const _item = parentBranch.splice(index, 1)[0]
  addToParent({
    item: _item,
    parentItem,
    multiLevelList
  })

  return {
    moved: true,
    idChange: false
  }
}
