/*
  import { moveItem } from '@/utilities/nestedSort/multiLevelList/moveItem'
  moveItem ({
    id,
    parentId,
    multiLevelList
  })
*/

import { objKeys } from '@/utilities/Object/keys'

import { findId } from './findId'

export function moveItem({ id, parentId, multiLevelList }) {
  console.log('moveItem', { id, parentId, multiLevelList })

  const theParentItem =
    typeof parentId !== 'number'
      ? {
          item: {
            items: multiLevelList
          },
          parentBranch: false,
          index: false
        }
      : findId({
          branch: multiLevelList,
          id: parentId
        })

  const theItem = findId({
    branch: multiLevelList,
    id
  })

  console.log({ theParentItem, theItem })

  if (objKeys(theParentItem.item).length >= 1 && objKeys(theItem.item).length >= 1) {
    const item = theItem.parentBranch.splice(theItem.index, 1)[0]
    theParentItem.item.items.push(item)

    return true
  }

  console.error(theParentItem, theItem)
  throw new Error(`The move of ${id} into ${parentId} was unsuccessful.`)
}
