/*
  import { findPrev } from '@/utilities/nestedSort/multiLevelList/findPrev'
  const result = findPrev ({
    id,
    multiLevelList
  })
*/

/*  
  result {
    id
    items
  }
*/

import { findId } from '@/utilities/nestedSort/multiLevelList/findId'

function climb({ items, index }) {
  const previousSibling = items[index]
  const previousSiblingItems = items[index].items
  //
  // last index of items of previous sibling
  const lastIndex = previousSiblingItems.length - 1

  if (previousSiblingItems.length === 0) {
    return previousSibling
  }

  const _item = previousSiblingItems[lastIndex]

  return climb({
    item: _item,
    items: previousSiblingItems,
    index: lastIndex
  })
}

export function findPrev({ id, multiLevelList }) {
  const { index, parentBranch, parentId } = findId({
    branch: multiLevelList,
    id
  })

  /*
    if is the first item,
    there is no previous item
    
    for example:
      _
        [ ] <- first item, no previous items
          [ ]
          [ ]
        [ ]
          [ ]
            [ ]
  */
  if (index === 0) {
    if (parentId === null) {
      return {
        id: null,
        items: parentBranch
      }
    }

    return {
      id: parentId,
      items: parentBranch
    }
  }

  /*
    for example:
      _
        [2]
        [1]
          [ ]
          [ ]
          [ ]
        [3] <- 
        [4]
      
      parentBrach length = 4
        parent branch: [2][1][3][4]
      
      index = 2
  */
  const theItem = findId({
    branch: parentBranch,
    id
  })

  const foundItem = climb({
    item: theItem.item,
    items: theItem.parentBranch,
    index: index - 1
  })

  /*
    { id: Number, items: Array }
  */
  return foundItem
}
