/*
  // note: makes modifications to the multi level list 

  import { calculateStartTimes } from '@/utilities/nestedSort/multiLevelList/calculateStartTimes'
  
  calculateStartTimes ({
    items: this.multiLevelList,
    //
    // '3191-12-20T21:10:00'
    startTime = ''
  })
*/

import moment from 'moment'

export function calculateStartTimes({ items, startTime }) {
  let mStartTime = moment(startTime)

  function updateBranch({ branch }) {
    for (let a = 0; a < branch.length; a++) {
      const item = branch[a]
      const duration = item.duration || 0

      const items = item.items

      /*
      console.log ({
        startTime: mStartTime.format ('hh:mm'),
        id: item.id
      })
      */

      item.startTime = mStartTime.format('hh:mm')
      mStartTime = mStartTime.add(duration, 'minutes')

      if (Array.isArray(items) && items.length >= 1) {
        updateBranch({
          branch: items
        })
      }
    }
  }

  updateBranch({ branch: items })
}
