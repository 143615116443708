/*
	import { modifyBoardPackage } from '@/services/BoardMeetings/BoardPackage/Put';

  const { phonePackage } = await modifyBoardPackage ({
    json: {
			hoaID,
      
      phoneNumber,
      
      "meetingDate": "2022-09-20T06:45:23.271Z",
      "financialMonth": "2022-09-20T06:45:23.271Z",
      
      //
      //  "Executive"
      //  "Regular"
      //
      "type": "string",
      
      "agendaJson": "{}",
      
      ///////
      // optional
      //
      // "physicalMeetingID": 6,
      // "virtualMeetingID": 4,
      // "phoneMeetingID": 4,
    }
  });
*/

/*
  exception.fields {
    phoneNumber: [ '' ],    
  }
*/

import kms from '@/services/kms'
import { notifyError } from '@/services/notify'

export async function modifyBoardPackage({ json }) {
  let exception = ''

  try {
    const result = await kms.put(`/BoardMeetings/BoardPackage`, json)

    const message = 'The board meeting was saved successfully.'

    return {
      successful: true,
      message,
      exception: null,
      phoneMeeting: result
    }
  } catch (_exception) {
    exception = _exception
  }

  let message = 'The board meeting could not be saved.'
  notifyError(message)

  return {
    successful: false,
    message,
    exception,
    phoneMeeting: null
  }
}
