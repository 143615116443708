/*
  import { setStyles } from '@/utilities/Element/setStyles'

  setStyles ({
    width: '100%'
  }, element);
*/
export const setStyles = (styles, element) => {
  for (const style in styles) {
    element.style[style] = styles[style]
  }
}
