/*
  import { filterMultiLevelList } from '@/utilities/nestedSort/filterMultiLevelList'
  const result = filterMultiLevelList ({
    params: [ 'items', 'duration', 'title' ],
    multiLevelList
  })
*/

/*
  for filtering out unecessary params before sending
  the list to the server (etc.)
*/

import _cloneDeep from 'lodash/cloneDeep'

function filterBranch({ branch, params }) {
  for (let a = 0; a < branch.length; a++) {
    const item = branch[a]
    const items = item.items

    for (let key in item) {
      if (!params.includes(key)) {
        delete item[key]
      }
    }

    if (Array.isArray(items) && items.length >= 1) {
      filterBranch({
        branch: items,
        params
      })
    }
  }
}

export function filterMultiLevelList({ multiLevelList, params }) {
  const result = _cloneDeep(multiLevelList)

  filterBranch({
    branch: result,
    params
  })

  return result
}
