import { getBoardPackage } from '@/services/BoardMeetings/BoardPackage/Get'

import { notifyError } from '@/services/notify'

import { create } from './list/create'
import { buildMultiLevel } from './list/buildMultiLevel'
import { _buildOneLevelList } from './list/buildOneLevelList'
import { save } from './list/save'
import { erase } from './list/erase'
import { add } from './list/add'
import { edit } from './list/edit'
import { openEditModal } from './list/openEditModal'
import { openAddModal } from './list/openAddModal'
import { preview } from './list/preview'

import { _moveUp } from './list/moveUp'
import { _moveDown } from './list/moveDown'

export const methods = {
  erase,
  save,

  buildMultiLevel,
  buildOneLevelList: _buildOneLevelList,
  create,
  add,
  edit,
  openEditModal,

  moveUp: _moveUp,
  moveDown: _moveDown,

  preview,

  async itemModalClosed({ dataId, mode }) {
    await new Promise(resolve => {
      setTimeout(() => {
        resolve()
      }, 500)
    })

    if (typeof dataId === 'number') {
      if (mode === 'add') {
        try {
          document
            .querySelectorAll('#nested-sortable-agenda')[0]
            .querySelectorAll(`[data-id="${dataId}"]`)[0]
            .querySelectorAll(`[adder]`)[0]
            .focus()
        } catch (x) {
          console.error(x)
        }
      } else if (mode === 'edit') {
        try {
          document
            .querySelectorAll('#nested-sortable-agenda')[0]
            .querySelectorAll(`[data-id="${dataId}"]`)[0]
            .querySelectorAll(`[editor]`)[0]
            .focus()
        } catch (x) {
          console.error(x)
        }
      }

      return
    }

    try {
      this.$refs.openAddModalButton.$el.focus()
    } catch (x) {
      console.error(x)
    }
  },

  getWidths() {
    if (['tablet', 'mobile'].includes(this.breakpoint)) {
      return ['30%', '30%', '30%']
    }

    return ['30%', '13%', '10%']
  },

  async getAgenda() {
    await getBoardPackage({
      boardPackageID: this.id
    }).then(({ successful, message, boardPackage }) => {
      if (!successful) {
        notifyError(message)
        return
      }

      this.boardPackage = boardPackage

      this.multiLevelList = JSON.parse(this.boardPackage.agendaJson)

      // for the sake of checking if "saved"
      this.retrievedAgenda = Object.seal(this.multiLevelList)

      this.buildOneLevelList()
      this.buildMultiLevel()
    })
  },

  openAddModal,

  async refresh() {
    this.widths = this.getWidths()

    await this.getAgenda()
    await this.create()
  }
}
