/*
	import { saveAgendaReport } from '@/services/BoardMeetings/Reports/AgendaReport/Save';

  const { result, successful, message } = await saveAgendaReport ({
    params: {
      boardPackageID,
      format: "PDF"
    }
  });
*/

/*
  exception.fields {
    phoneNumber: [ '' ],    
  }
*/

import kms from '@/services/kms'
import { notifyError } from '@/services/notify'

export async function saveAgendaReport({ params }) {
  let exception = ''

  try {
    const result = await kms.get(`/BoardMeetings/Reports/AgendaReport/Save`, {
      params
    })

    return {
      successful: true,
      message: 'The board meeting agenda report was saved successfully.',
      exception: null,
      result
    }
  } catch (_exception) {
    exception = _exception
  }

  let message = 'A problem occurred and the board meeting agenda report could not be saved.'
  notifyError(message)

  return {
    successful: false,
    message,
    exception,
    result: null
  }
}
