export function editEl({ component, duration, id }) {
  return {
    kind: 'button',
    className: 'button is-info is-light is-small is-outline is-rounded',
    textContent: 'Edit',
    value: duration,
    attributes: {
      editor: ''
    },
    styles: {
      display: 'inline-block',
      marginLeft: '4px'
    },
    on: {
      click: function(event) {
        event.preventDefault()
        event.stopPropagation()

        component.openEditModal({ id, event })
      }
    },
    title: 'edit',
    children: [
      {
        kind: 'div',
        className: '',
        styles: {
          backgroundSize: 'contain',
          height: '0px',
          width: '0px',
          transform: 'scale(1.6)'
        }
      }
    ]
  }
}
