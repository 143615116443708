/*
  import { buildOneLevelList } from '@/utilities/nestedSort/buildOneLevelList'
  const oneLevelList = buildOneLevelList ({
    multiLevelList,
    //
    params: [ 'title', 'duration' ]
  })
*/

function readBranch({ branch, params, result, parent = undefined }) {
  for (let a = 0; a < branch.length; a++) {
    const item = branch[a]
    const id = result.length + 1
    const order = a + 1
    const items = item.items

    const extras = {}
    for (let b = 0; b < params.length; b++) {
      const param = params[b]

      extras[param] = item[param]
    }

    result.push({
      id,
      parent,
      order,
      ...extras
    })

    if (Array.isArray(items) && items.length >= 1) {
      readBranch({
        branch: items,
        params,
        result,
        parent: id
      })
    }
  }
}

export function buildOneLevelList({ multiLevelList, params = [] }) {
  const result = []

  readBranch({
    result,
    params,
    branch: multiLevelList
  })

  return result
}
