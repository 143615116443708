/*
  import { buildMultiLevelList } from '@/utilities/nestedSort/multiLevelList/build'
  const multiLevelList = buildMultiLevelList ({
    oneLevelList,
    //
    // '3191-12-20T21:10:00'
    startTime = ''
  })
*/

import _cloneDeep from 'lodash/cloneDeep'

import { calculateStartTimes } from './calculateStartTimes'

// climb
function tryInsert({ item, items }) {
  for (let a = 0; a < items.length; a++) {
    const branch = items[a]

    if (branch.id === item.parent) {
      const itemOrderIndex = item.order - 1
      items[a].items[itemOrderIndex] = item
      return true
    }

    if (Array.isArray(branch.items) && branch.items.length >= 1) {
      const found = tryInsert({
        item,
        items: branch.items
      })
      if (found) {
        return true
      }
    }
  }

  return false
}

export function buildMultiLevelList({
  oneLevelList: _list,
  //
  // '3191-12-20T21:10:00'
  startTime = ''
}) {
  const items = []

  const list = _cloneDeep(_list)

  /*
    add all the items without a parent to branches
  */
  for (let a = 0; a < list.length; a++) {
    const item = list[a]
    item.items = []

    const parent = item.parent
    const orderIndex = item.order - 1

    if (parent === undefined) {
      //
      //  no parent was specified
      //

      // add to the level at the orderIndex specified.
      items.splice(orderIndex, 0, item)

      // remove item from the input list
      list.splice(a, 1)
      a--

      continue
    }
  }

  const maxIteration = 4
  let currentIteration = 0

  while (list.length >= 1 && currentIteration <= maxIteration) {
    for (let a = 0; a < list.length; a++) {
      const item = list[a]
      item.items = []

      const inserted = tryInsert({
        item,
        items
      })

      if (inserted) {
        list.splice(a, 1)
        a--
      }
    }

    currentIteration++
  }

  calculateStartTimes({
    items,
    startTime
  })

  return items
}
