<template>
  <div role="main" data-id="meetings agenda">
    <div
      :style="{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }"
    >
      <div>
        <b-button class="is-rounded is-primary" @click="openAddModal" ref="openAddModalButton"
          >Add</b-button
        >

        <span :style="{ display: 'inline-block', width: '10px' }" />
        <b-button class="is-primary is-rounded" @click="preview">Preview Document</b-button>
        <span :style="{ display: 'inline-block', width: '10px' }" />
      </div>
    </div>

    <div><b-tag :style="{ marginTop: '10px' }">The max depth is 3.</b-tag></div>

    <div :style="{ height: '20px' }" />

    <div
      class="pb-4"
      :style="{
        float: 'right',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around'
      }"
    >
      <div
        :style="{
          position: 'relative',
          paddingRight: '6px'
        }"
      >
        <b-icon
          icon="loading"
          size="is-small"
          :style="{
            animation: 'spinAround .5s infinite linear',
            opacity: status === 'saving' ? 1 : 0
          }"
        />
      </div>
      <div
        v-if="status === 'saved'"
        :style="{
          background: '#ddd',
          padding: '2px 0px',
          width: '60px',
          textAlign: 'center',
          borderRadius: '4px'
        }"
      >
        saved
      </div>
      <div
        v-if="status === 'saving'"
        :style="{
          background: '#ddd',
          padding: '2px 0px',
          width: '60px',
          textAlign: 'center',
          borderRadius: '4px'
        }"
      >
        saving
      </div>
    </div>

    <div :style="{ display: 'flex' }">
      <div :style="{ width: widths[0], paddingLeft: '20px' }">title</div>
      <div :style="{ width: widths[1], paddingLeft: '10px' }">duration</div>
      <div :style="{ width: widths[2], paddingLeft: '10px' }">start time</div>
      <div></div>
    </div>

    <div id="nested-sortable-agenda"></div>

    <EmptyList v-if="oneLevelList.length === 0" />

    <ItemModal ref="itemModal" :onClose="itemModalClosed" />
  </div>
</template>

<script>
import _get from 'lodash/get'

import { watch } from './keys/watch'
import { methods } from './keys/methods'
import { data } from './keys/data'
import { computed } from './keys/computed'

import ItemModal from './components/ItemModal'

import EmptyList from '@/components/EmptyList'

/*

*/
export default {
  components: {
    ItemModal,
    EmptyList
  },
  computed,
  data,
  methods,
  watch,
  mounted() {
    this.id = parseInt(_get(this, ['$route', 'params', 'id'], ''))

    console.log('mounted', this.id)

    this.refresh()
  }
}
</script>

<style lang="scss">
/* nested-sort is the custom class name */
.nested-sort {
  padding: 0;
}

.nested-sort--enabled li {
  cursor: move;
}

.nested-sort li {
  list-style: none;
  margin: 0 0 5px;
  padding: 10px;
  background: #fff;
  border: 1px solid #ddd;
}

.nested-sort li ol {
  padding: 0;
  margin-top: 10px;
  margin-bottom: -5px;
}

/* ns-dragged is the class name of the item which is being dragged */
.nested-sort .ns-dragged {
  border: 1px solid red;
}

/* ns-targeted is the class name of the item on which the dragged item is hovering */
.nested-sort .ns-targeted {
  border: 1px solid green;
}
</style>
