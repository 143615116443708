/*
  import { findId } from '@/utilities/nestedSort/multiLevelList/findId'
  const theItem = findId ({ 
    branch: multiLevelList,
    id
  });
*/

function determineLevels({ item }) {
  let levels = 1

  const items = item.items
  if (Array.isArray(items) && items.length >= 1) {
    levels++
  }

  let maxLevels = 0
  for (let a = 0; a < items.length; a++) {
    const _item = items[a]
    const _items = _item.items

    if (Array.isArray(_items) && _items.length >= 1) {
      const _levels =
        determineLevels({
          item: _item
        }) - 1

      if (_levels > maxLevels) {
        maxLevels = _levels
      }
    }
  }

  return levels + maxLevels
}

export function findId({ parentId = null, branch, id, depth = 1 }) {
  // console.log ('searching for', id, 'in', parentId, ', depth:', depth);

  for (let a = 0; a < branch.length; a++) {
    const item = branch[a]
    const { id: _id, items } = item

    if (_id === id) {
      return {
        item,
        parentId,
        parentBranch: branch,
        index: a,
        found: true,
        depth: depth,
        levels: determineLevels({
          item
        })
      }
    }

    if (Array.isArray(items) && items.length >= 1) {
      const parentId = _id

      const {
        found,
        //
        item: _item,
        index: _index,
        //
        parentId: _parentId,
        parentBranch: _parentBranch,
        //
        depth: _depth
      } = findId({
        branch: items,
        id,
        parentId,
        depth: depth + 1
      })

      // console.log ({ found, _depth, depth, _item });

      // objKeys (_item).length >= 1
      if (found) {
        return {
          found: true,
          //
          item: _item,
          parentId: _parentId,
          parentBranch: _parentBranch,
          index: _index,
          //
          depth: _depth,
          levels: determineLevels({
            item: _item
          })
        }
      }
    }
  }

  return {
    found: false,
    //
    item: false,
    parentId: false,
    parentBranch: false,
    index: false,
    //
    depth: false
  }
}
