var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"role":"main","data-id":"meetings agenda"}},[_c('div',{style:({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    })},[_c('div',[_c('b-button',{ref:"openAddModalButton",staticClass:"is-rounded is-primary",on:{"click":_vm.openAddModal}},[_vm._v("Add")]),_c('span',{style:({ display: 'inline-block', width: '10px' })}),_c('b-button',{staticClass:"is-primary is-rounded",on:{"click":_vm.preview}},[_vm._v("Preview Document")]),_c('span',{style:({ display: 'inline-block', width: '10px' })})],1)]),_c('div',[_c('b-tag',{style:({ marginTop: '10px' })},[_vm._v("The max depth is 3.")])],1),_c('div',{style:({ height: '20px' })}),_c('div',{staticClass:"pb-4",style:({
      float: 'right',
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-around'
    })},[_c('div',{style:({
        position: 'relative',
        paddingRight: '6px'
      })},[_c('b-icon',{style:({
          animation: 'spinAround .5s infinite linear',
          opacity: _vm.status === 'saving' ? 1 : 0
        }),attrs:{"icon":"loading","size":"is-small"}})],1),(_vm.status === 'saved')?_c('div',{style:({
        background: '#ddd',
        padding: '2px 0px',
        width: '60px',
        textAlign: 'center',
        borderRadius: '4px'
      })},[_vm._v(" saved ")]):_vm._e(),(_vm.status === 'saving')?_c('div',{style:({
        background: '#ddd',
        padding: '2px 0px',
        width: '60px',
        textAlign: 'center',
        borderRadius: '4px'
      })},[_vm._v(" saving ")]):_vm._e()]),_c('div',{style:({ display: 'flex' })},[_c('div',{style:({ width: _vm.widths[0], paddingLeft: '20px' })},[_vm._v("title")]),_c('div',{style:({ width: _vm.widths[1], paddingLeft: '10px' })},[_vm._v("duration")]),_c('div',{style:({ width: _vm.widths[2], paddingLeft: '10px' })},[_vm._v("start time")]),_c('div')]),_c('div',{attrs:{"id":"nested-sortable-agenda"}}),(_vm.oneLevelList.length === 0)?_c('EmptyList'):_vm._e(),_c('ItemModal',{ref:"itemModal",attrs:{"onClose":_vm.itemModalClosed}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }