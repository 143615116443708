export function upArrowEl({ component, duration, id }) {
  function press(event) {
    event.preventDefault()
    event.stopPropagation()

    component.moveUp({ id, component })
  }

  return {
    kind: 'button',
    className: 'button',
    title: 'move up',
    value: duration,
    attributes: {
      'up-arrow': ''
    },
    styles: {
      display: 'inline-block',
      marginLeft: '4px'
    },
    on: {
      click: press,
      keypress: press
    },
    children: [
      {
        kind: 'div',
        className: 'icon-uparrow',
        styles: {
          height: '20px',
          width: '20px',
          backgroundSize: 'contain'
        }
      }
    ]
  }
}
