import { calcValidParents } from '@/utilities/nestedSort/multiLevelList/calcValidParents'
import { findId } from '@/utilities/nestedSort/multiLevelList/findId'

import _get from 'lodash/get'

export function openAddModal({ id = null } = {}) {
  try {
    console.debug('in openAddModal...')
    this.buildMultiLevel()
    console.debug('in this.buildMultiLevel()...')
    const multiLevelList = this.multiLevelList
    console.debug('in multiLevelList...')

    const item = findId({
      branch: multiLevelList,
      id
    })

    const parentItems = calcValidParents({
      id: null,
      multiLevelList,
      maxDepth: this.maxDepth
    })

    this.$refs.itemModal.open({
      mode: 'add',
      parentItems,
      parentId: id,
      dataId: _get(item, ['item', 'id'], null),
      addAgendaItem: params => {
        console.debug('addAgendaItem=' + JSON.stringify(params))
        this.add(params)
      }
    })
  } catch (ex) {
    console.debug('error=' + ex.message)
  }
}
