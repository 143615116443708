import { buildOneLevelList } from '@/utilities/nestedSort/buildOneLevelList'

export function _buildOneLevelList() {
  this.oneLevelList = buildOneLevelList({
    multiLevelList: this.multiLevelList,
    params: ['title', 'duration', 'startTime']
  })

  return this.oneLevelList
}
