import { notifyMessage } from '@/services/notify'
import { findId } from '@/utilities/nestedSort/multiLevelList/findId'

export async function erase({ id, title }) {
  const item = findId({
    branch: this.multiLevelList,
    id
  })

  await new Promise(resolve => {
    this.$buefy.dialog.confirm({
      title: 'Deleting Agenda Item',
      message: `Are you sure you want to <b>delete</b> "${title}"?  This will delete any sub-items of "${title}" as well.`,
      confirmText: 'Delete Account',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => {
        resolve()
      }
    })
  })

  item.parentBranch.splice(item.index, 1)

  /*
    erase from multi-level list    
  */

  notifyMessage(`"${title}" was deleted.`)

  try {
    this.$refs.openAddModalButton.$el.focus()
  } catch (x) {
    console.error(x)
  }

  this.create()
}
