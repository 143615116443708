/*
  builds the one level list on every call,
  from the multi level list
*/

import { createEl } from '@/utilities/Element/create'
import NestedSort from 'nested-sort'

import _cloneDeep from 'lodash/cloneDeep'

import { addEl } from './functions/addEl'
import { editEl } from './functions/editEl'
import { eraseEl } from './functions/eraseEl'
import { downArrowEl } from './functions/downArrowEl'
import { upArrowEl } from './functions/upArrowEl'

export async function create() {
  const component = this
  const widths = this.widths

  const oneLevelList = this.buildOneLevelList()
  const data = _cloneDeep(oneLevelList)
  if (data.length === 0) {
    try {
      document.querySelectorAll('#nested-sortable-agenda')[0].textContent = ''
    } catch (x) {
      console.error(x)
    }
  }

  this.buildMultiLevel()

  new NestedSort({
    data: [],
    el: '#nested-sortable-agenda',
    listClassNames: ['nested-sort'],
    renderListItem: () => {}
  })

  new NestedSort({
    data,
    el: '#nested-sortable-agenda',
    listClassNames: ['nested-sort'],
    nestingLevels: this.maxDepth - 1,
    renderListItem: (el, params) => {
      let { title, duration, id, startTime } = params
      if (typeof duration === 'number') {
        duration = duration.toString()
      }

      el.textContent = ''
      el.style.display = 'block'
      el.style.justifyContent = 'space-around'
      el.style.width = '100%'
      el.style.paddingBottom = '10px'
      el.style.borderRadius = '6px'
      el.style.position = 'relative'
      el.style.overflow = 'hidden'

      const display = 'inline-block'
      const height = '40px'
      const paddingLeft = '10px'

      const titleEl = createEl({
        kind: 'div',
        styles: {
          display,
          width: widths[0],
          textAlign: 'left',

          ...(typeof title !== 'string' || title.length === 0 ? { height: 0 } : { height }),

          paddingLeft
        },

        children: [
          {
            kind: 'div',
            textContent: title,
            value: title,
            styles: {
              width: '90%',
              position: 'relative',
              top: '50%',
              transform: 'translateY(-50%)'
            }
          }
        ]
      })

      const durationEl = createEl({
        kind: 'div',
        styles: {
          position: 'relative',
          display,
          width: widths[1],
          height,
          paddingLeft
        },

        children: [
          {
            kind: 'div',
            textContent: duration,
            styles: {
              display: 'inline-block',
              position: 'relative',
              top: '50%',
              transform: 'translateY(-50%)'
            },
            on: {
              click: function(e) {
                e.preventDefault()
                e.stopPropagation()
              }
            }
          },
          {
            kind: 'div',
            textContent: 'minutes',
            styles: {
              display: 'inline-block',
              position: 'relative',
              top: '50%',
              transform: 'translateY(-50%)',
              paddingLeft: '8px'
            },
            on: {
              click: function(e) {
                e.preventDefault()
                e.stopPropagation()
              }
            }
          }
        ]
      })

      const startTimeEl = createEl({
        kind: 'div',
        styles: {
          position: 'relative',
          display,
          width: widths[2],
          paddingLeft
        },
        children: [
          {
            kind: 'div',
            className: 'tag',
            styles: {
              position: 'absolute',
              top: 'calc(50% + 3px)',
              transform: 'translateY(-50%)'
            },
            textContent: startTime
          }
        ]
      })

      const buttonsEl = createEl({
        kind: 'div',
        styles: {
          display,
          float: 'right',
          paddingLeft,
          marginBottom: '10px',
          textAlign: 'right'
        },
        children: [
          downArrowEl({
            component,
            duration,
            id
          }),
          upArrowEl({
            component,
            duration,
            id
          }),
          addEl({
            component,
            duration,
            id
          }),
          editEl({
            component,
            duration,
            id
          }),
          eraseEl({
            component,
            duration,
            id,
            title
          })
        ]
      })

      el.appendChild(titleEl)
      el.appendChild(durationEl)
      el.appendChild(startTimeEl)

      el.appendChild(buttonsEl)

      return el
    },
    actions: {
      /*
        list returned:
          problems:
            id:
              string -> integer
              
            parent:
              string -> integer
              
            add params:
              duration 
              title
      */
      onDrop(list) {
        const oneLevelListWithParams = _cloneDeep(component.oneLevelList)

        /*
          add "id" and "parent" as integers
        */
        const result = _cloneDeep(list).map(item => {
          const id = parseInt(item.id)

          return {
            ...item,
            id,
            ...(typeof item.parent === 'string' ? { parent: parseInt(item.parent) } : {})
          }
        })

        /*
          add params: "duration" & "title"
        */
        for (let a = 0; a < result.length; a++) {
          const item = oneLevelListWithParams.find(item => {
            return item.id === result[a].id
          })

          result[a].duration = item.duration
          result[a].title = item.title
        }

        component.oneLevelList = result

        component.buildMultiLevel()

        component.create()
      }
    }
  })
}
