import { findId } from '@/utilities/nestedSort/multiLevelList/findId'
import { calculateStartTimes } from '@/utilities/nestedSort/multiLevelList/calculateStartTimes'

// get the id after the highest id
// in the one level list
function nextId({ oneLevelList }) {
  let highestId = 1
  for (let a = 0; a < oneLevelList.length; a++) {
    const item = oneLevelList[a]

    if (item.id > highestId) {
      highestId = item.id
    }
  }

  const id = highestId + 1

  return id
}

export function add({ title = '', duration = 0, parent = null, reselected = '' }) {
  const multiLevelList = this.multiLevelList

  const id = nextId({
    oneLevelList: this.oneLevelList
  })

  console.debug('reselected=' + reselected + ', parent===' + JSON.stringify(parent))

  if (typeof parent === 'number') {
    const theItem = findId({
      branch: multiLevelList,
      id: parent
    })

    theItem.item.items.push({
      title,
      duration,

      id,
      order: theItem.item.items.length
    })
  } else {
    multiLevelList.push({
      title,
      duration,

      id,
      order: multiLevelList.length
    })
  }

  const startTime = this.boardPackage.meetingDate
  calculateStartTimes({
    items: this.multiLevelList,
    //
    // '3191-12-20T21:10:00'
    startTime
  })

  this.create()
}
