import { moveDown } from '@/utilities/nestedSort/multiLevelList/moveDown'
import { calculateStartTimes } from '@/utilities/nestedSort/multiLevelList/calculateStartTimes'
import { save } from './save'

export async function _moveDown({ id, component }) {
  const { idChange } = moveDown({
    multiLevelList: this.multiLevelList,
    id,
    maxDepth: this.maxDepth
  })

  const startTime = this.boardPackage.meetingDate
  calculateStartTimes({
    items: this.multiLevelList,
    // '3191-12-20T21:10:00'
    startTime
  })

  component.create()

  function focus() {
    let _id = id
    if (idChange) {
      _id++
    }

    try {
      document
        .querySelectorAll('#nested-sortable-agenda')[0]
        .querySelectorAll(`[data-id="${_id}"]`)[0]
        .querySelectorAll(`[down-arrow]`)[0]
        .focus()
    } catch (x) {
      console.error(x)
    }
  }

  setTimeout(() => {
    focus()
  }, 500)
  setTimeout(() => {
    focus()
    this.status = 'saving'
    save(this.boardPackage, this.multiLevelList)
    this.refresh()
    this.savingTimeout = setTimeout(() => {
      this.status = 'saved'
    }, 300)
  }, 1000)
}
