import { saveAgendaReport } from '@/services/BoardMeetings/Reports/AgendaReport/Save'
import { getAgendaReportBase64 } from '@/services/BoardMeetings/Reports/AgendaReport/GetAsBaseSixtyFour'
import { base64toBlob, showPdf } from '@/services/blobutility'

import { notifyError } from '@/services/notify'

export async function preview() {
  this.loading = true

  const boardPackageID = this.boardPackage.boardPackageID

  await saveAgendaReport({
    params: {
      boardPackageID,
      format: 'PDF'
    }
  }).then(({ result, successful, message }) => {
    if (!successful) {
      notifyError(message)
      throw new Error(message)
    }

    return { result }
  })

  const { report, successful, message } = await getAgendaReportBase64({
    params: {
      boardPackageID,
      format: 'PDF'
    }
  })
  if (!successful) {
    notifyError(message)
    throw new Error(message)
  }

  const blob = base64toBlob(report, 'application/pdf')
  showPdf(blob)

  this.loading = false
}
