import { setStyles } from './setStyles'

export function createEl({
  kind = '',
  className = null,
  textContent = null,
  title = null,

  styles = {},
  attributes = {},
  value = null,
  children = [],
  on = {}
}) {
  var el = document.createElement(kind)

  setStyles(styles, el)

  if (value !== null) {
    el.value = value
  }
  if (typeof className === 'string') {
    el.className = className
  }
  if (typeof textContent === 'string') {
    el.textContent = textContent
  }
  if (typeof title === 'string') {
    el.title = title
  }

  for (const key in attributes) {
    const value = attributes[key]
    el.setAttribute(key, value)
  }

  for (let a = 0; a < children.length; a++) {
    el.appendChild(createEl(children[a]))
  }

  try {
    for (let key in on) {
      el.addEventListener(key, event => {
        on[key](event)
      })
    }
  } catch (x) {
    console.error(x)
  }

  return el
}

///
