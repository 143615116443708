/*
  import { moveUp } from '@/utilities/nestedSort/multiLevelList/moveUp'
  const theItem = moveUp ({ 
    multiLevelList,
    id
  });
*/

import { findId } from '@/utilities/nestedSort/multiLevelList/findId'
import { findPrev } from '@/utilities/nestedSort/multiLevelList/findPrev'

function addToParent({ parentItem, item }) {
  const { parentBranch, index } = parentItem

  parentBranch.splice(index, 0, item)
}

export function moveUp({ multiLevelList, id, maxDepth = 10, notes = 0 }) {
  const item = findId({
    branch: multiLevelList,
    id
  })

  const parentBranch = item.parentBranch
  const parentId = item.parentId
  const index = item.index

  const isFirst = item.index === 0
  if (notes) {
    console.log({ isFirst })
  }

  if (!isFirst) {
    //
    //  The item is not the last in the parent's item
    //  array, therefore it can be moved up into the last
    //  and deepest leaf of the previous item.
    //
    //  * <- indicates "not first"
    //
    /*
      [1]
         [2]
            [3]
            [4*]
      [5*]         <- attach to [1] after [2]
         [6 ]
         [7*]      <- flip with [6]
      [8*]         <- flip with [5]
         [9]
            [10]
            [11*]  <- flip with [10]
    */

    const itemLevels = item.levels
    if (notes) {
      console.log({ itemLevels })
    }

    let prev
    let _id = id

    let a = 1
    while (a <= 20) {
      prev = findPrev({
        id: _id,
        multiLevelList
      })
      if (prev.id === null) {
        if (notes) {
          console.log('attach as first item of parent')
        }

        const _item = parentBranch.splice(index, 1)[0]
        multiLevelList.splice(0, 0, _item)

        return {
          code: 0,
          moved: true,
          idChange: true
        }
      }

      const prevItem = findId({
        branch: multiLevelList,
        id: prev.id
      })

      const found = prevItem.found
      if (!found) {
        if (notes) {
          console.log('no previous item of', _id, 'add to beginning of multi level list')
        }

        const _item = parentBranch.splice(index, 1)[0]
        multiLevelList.splice(0, 0, _item)
        return {
          code: 1,
          moved: true,
          idChange: true
        }
      }

      _id = prev.id

      /*
      
      
      */
      const resultantLevel = itemLevels + prevItem.depth
      if (resultantLevel <= maxDepth) {
        const attachTo = findId({
          branch: multiLevelList,
          id: _id
        })

        if (notes) {
          console.log('same branch', {
            attachTo: attachTo
          })
        }

        const _item = parentBranch.splice(index, 1)[0]
        const _items = attachTo.item.items

        if (notes) {
          console.log('same branch', {
            attachTo: attachTo,
            _item,
            _items
          })
        }

        //
        //  This was problematic, but maybe there
        //  is a condition where should be at front of
        //  array....
        //
        // _items.splice(index - 1, 0, _item)

        _items.push(_item)

        return {
          code: 2,
          moved: true,
          idChange: true
        }
      }

      a++
    }

    /*
      determine this for each possible previous item
    */
    return {
      code: 3,
      moved: true,
      idChange: true
    }
  }

  if (isFirst && typeof parentId !== 'number') {
    //
    //  The item is the first item of the level 0 array
    //  therefore, it can't be moved up any more.
    //
    return {
      code: 4,
      moved: false,
      idChange: false
    }
  }

  const parentItem = findId({
    branch: multiLevelList,
    id: parentId
  })

  const _item = parentBranch.splice(index, 1)[0]
  addToParent({
    item: _item,
    parentItem,
    multiLevelList
  })

  return {
    code: 5,
    moved: true,
    idChange: true
  }
}
