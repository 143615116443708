import { calcValidParents } from '@/utilities/nestedSort/multiLevelList/calcValidParents'
import { moveItem } from '@/utilities/nestedSort/multiLevelList/moveItem'
import { findId } from '@/utilities/nestedSort/multiLevelList/findId'

export async function openEditModal({ id }) {
  this.buildMultiLevel()
  const multiLevelList = this.multiLevelList

  this.rselect = 'activate'

  const item = findId({
    branch: multiLevelList,
    id
  }).item

  const parentItems = calcValidParents({
    id,
    multiLevelList,
    maxDepth: this.maxDepth
  })

  console.debug('this.rselect=' + this.rselect)

  await new Promise(resolve => {
    this.$refs.itemModal.open({
      mode: 'edit',
      //
      duration: item.duration,
      title: item.title,
      parentItems,
      parentId: this.reselect === 'none' ? 0 : item.parent,
      dataId: id,
      //
      editAgendaItem: params => {
        let { parent: parentId, duration, title } = params

        if (this.rselect === 'none') {
          parentId = null
          this.isDisabled = true
        } else {
          this.isDisabled = false
          this.rselect = 'activate'
        }

        item.duration = duration
        item.title = title

        if (typeof parentId !== 'number' && typeof item.parent !== 'number') {
          resolve()
          return
        }

        if (parentId !== item.parent) {
          moveItem({
            id: item.id,
            parentId,
            multiLevelList
          })
        }

        resolve()
      }
    })
  })

  this.create()
}
