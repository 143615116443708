export function data() {
  return {
    found: false,
    saved: true,
    status: 'saved',
    nextId: 1,
    id: null,
    oneLevelList: [],
    multiLevelList: [],
    maxDepth: 3,
    widths: this.getWidths(),
    paddingLefts: ['10px']
  }
}
