import { filterMultiLevelList } from '@/utilities/nestedSort/filterMultiLevelList'

import isEqual from 'lodash/isEqual'

export const watch = {
  breakpoint() {
    this.widths = this.getWidths()
    this.create()
  },
  multiLevelList: {
    deep: true,
    handler(multiLevelList) {
      const retrievedAgenda = this.retrievedAgenda

      const filtered = filterMultiLevelList({
        params: ['items', 'duration', 'title', 'startTime'],
        multiLevelList
      })

      const equal = isEqual(filtered, retrievedAgenda)

      if (equal) {
        this.saved = true
        this.status = 'saved'
      } else {
        setTimeout(() => {
          this.saved = false
          this.status = 'saving'
          this.save()
          this.status = 'saved'
        }, 1000)
      }
    }
  }
}
