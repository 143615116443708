/*
  example:
    import { getAgendaReportBase64 } from '@/services/BoardMeetings/Reports/AgendaReport/GetAsBaseSixtyFour';

    const { report, successful, message } = await getAgendaReportBase64 ({
      params: {
        boardPackageID: '',
        asBase64: true
      }
    });
    if (!successful) {
      console.error (message);
      return;
    }
*/
//
import kms from '@/services/kms'
import { notifyError } from '@/services/notify'
//
export async function getAgendaReportBase64({ params }) {
  try {
    const result = await kms.get(`/BoardMeetings/Reports/AgendaReport/GetAsBaseSixtyFour`, {
      params
    })

    return {
      successful: true,
      message: '',
      report: result
    }
  } catch (exception) {
    console.error(exception)
  }

  let message = `A problem occurred and the agenda report could not be retrieved.`
  notifyError(message)

  return {
    successful: false,
    message,
    report: null
  }
}

///////
