<template>
  <Modal :toggle.sync="toggle" :styles="{ modalContent: { minHeight: '100px' } }">
    <b-field>
      <b-radio v-model="rselect" name="selectLevel" native-value="none" aria-label="none">
        Add at Top Level
      </b-radio>
    </b-field>
    <b-field>
      <b-radio v-model="rselect" name="selectLevel" native-value="activate" aria-label="activate">
        Add Under Existing Heading Item
      </b-radio>
    </b-field>

    <b-field label="Parent">
      <b-select v-model="parent" :disabled="isDisabled">
        <option v-for="(option, index) in parentItems" :value="option.id" :key="index">
          {{ option.text }}
        </option>
      </b-select>
    </b-field>

    <b-field label="Title">
      <b-input v-model="title"></b-input>
    </b-field>

    <b-field label="Duration (minutes)">
      <b-numberinput controls-position="compact" v-model="duration"></b-numberinput>
    </b-field>

    <template v-slot:header>
      <div v-if="mode === 'add'">Add Agenda Item</div>
      <div v-if="mode === 'edit'">Edit Agenda Item</div>
    </template>

    <template v-slot:footer>
      <b-button class="is-primary" @click="toggle = false">Close</b-button>
      <b-button class="is-primary" @click="add" :style="{ float: 'right' }" v-if="mode === 'add'"
        >Add</b-button
      >
      <b-button class="is-primary" @click="edit" :style="{ float: 'right' }" v-if="mode === 'edit'"
        >Save</b-button
      >
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal'

export default {
  props: {
    onClose: Function
  },
  components: {
    Modal
  },
  data() {
    const originalParentItems = Object.freeze([{ value: false, text: 'None', id: null }])

    return {
      toggle: false,
      originalParentItems,
      isDisabled: false,
      rselect: 'none',

      parentItems: [],

      mode: '',
      title: '',
      duration: 0,
      parent: null,
      dataId: null
    }
  },

  watch: {
    toggle(toggle) {
      if (toggle === false) {
        this.onClose({
          dataId: this.dataId,
          mode: this.mode
        })
      }
    },
    rselect() {
      if (this.rselect === 'none') {
        this.reselect = 'none'
        this.isDisabled = true
      } else {
        this.reselect = 'activate'
        this.isDisabled = false
      }
      console.debug('rselect has changed...' + this.reselect)
    }
  },

  methods: {
    open({
      addAgendaItem = function() {},
      editAgendaItem = function() {},
      parentItems = [],
      parentId = null,
      dataId = null,
      mode = 'add',
      duration = '',
      title = ''
    }) {
      this.mode = mode
      this.dataId = dataId

      this.rselect = 'none'

      console.debug('in open................................................' + mode)

      if (typeof parentId === 'number' || parentId !== null) {
        this.parent = parentId
        this.isDisabled = false
        console.debug(this.rselect + '=reselect, parentId is is not null=' + parentId)
      } else {
        this.parent = null
        this.isDisabled = true
        console.debug(this.rselect + '=reselect, parentId is null=' + parentId)
      }

      this.parentItems = []
      for (let a = 0; a < parentItems.length; a++) {
        const { title, duration, id } = parentItems[a]

        this.parentItems.push({
          text: `${title} (duration: ${duration})`,
          id
        })
      }

      if (this.parentItems.length == 0) {
        this.isDisabled = true
      }

      if (this.parent !== null && this.parentItems.length > 0) {
        this.rselect = 'activate'
      }

      if (mode === 'edit') {
        this.editAgendaItem = editAgendaItem
        this.duration = duration
        this.title = title
      } else if (mode === 'add') {
        this.addAgendaItem = addAgendaItem
        this.duration = 0
        this.title = ''
      }

      this.toggle = true
    },

    edit() {
      let { duration, title, parent } = this

      if (this.rselect === 'none') {
        parent = null
      }

      this.editAgendaItem({
        parent,
        duration,
        title
      })

      this.toggle = false
    },

    add() {
      const { duration, title, parent } = this

      console.debug(
        'duration, title, parent' +
          duration +
          ', ' +
          title +
          ', ' +
          parent +
          ', rselect=' +
          this.rselect +
          ', reselect=' +
          this.reselect
      )

      this.addAgendaItem({
        parent,
        duration,
        title,
        reselected: this.reselect
      })

      this.toggle = false
    }
  }
}
</script>
